import { Button } from '../common/Button/Button';
import { motion, useAnimation } from 'framer-motion';
import './NextBanner.css';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router';
import { CONTACT_ROUTE, REQUEST_AUDIT_PATH } from '../../constants/routes';

const NextBanner = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  const navigate = useNavigate();

  useEffect(() => {
    if (inView) {
      controls.start({ opacity: 1, y: 0 });
    }
  }, [inView]);

  return (
    <div className='banner'>
      <motion.div
        ref={ref}
        initial={{ opacity: 0, y: 75 }}
        animate={controls}
        transition={{ duration: 0.8 }}
        className='banner_content'
      >
        <div className='banner_content__wrapper'>
          <h3>Whats Next? Lets Chat!</h3>
          <Button type='button' className='banner_button' onClick={() => navigate(CONTACT_ROUTE)}>
            Contact us
          </Button>
          <Button type='button' onClick={() => navigate(REQUEST_AUDIT_PATH)}>
            Request Quote
          </Button>
        </div>
      </motion.div>
    </div>
  );
};

export { NextBanner };
