import './TickerItem.css';

const TickerItem = ({ children }) => {
  return (
    <div className='ticker_item'>
      <p>{children}</p>
    </div>
  );
};

export { TickerItem };
