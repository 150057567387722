import './ServicesPage.css';
import { ServicesCard } from '../../components/ServicesCard/ServicesCard';
import { NextBanner } from '../../components/NextBanner/NextBanner';
import { PageHeader } from '../../components/PageHeader/PageHeader';

const CARDS = [
  {
    header: 'Smart Contract Security Auditing',
    article: 'Manual, industry leading analysis, suggestions, and reporting.',
    src: 'https://uploads-ssl.webflow.com/62b0519d9f36464387cd34b7/62c2b6377f5d655f723a7604_padlock.svg',
  },
  {
    header: 'Corporate Blockchain Security',
    article:
      'Working with developers to ensure enterprises know when and how to correctly implement blockchain technology.',
    src: 'https://uploads-ssl.webflow.com/62b0519d9f36464387cd34b7/62c2b60db3c318c204c8d7e7_block.svg',
  },
  {
    header: 'Industry Research',
    article:
      'Contributing to the wider community and industry via vulnerability identification and informative resources.',
    src: 'https://uploads-ssl.webflow.com/62b0519d9f36464387cd34b7/62c2b61b65c2ff82c66a5ab5_block-fi.svg',
  },
  {
    header: 'Blockchain Cyber Insurance via our Registered Partners',
    article:
      'Coming soon! We work with our partners to protect projects and their community via blockchain cyber insurance.',
    src: 'https://uploads-ssl.webflow.com/62b0519d9f36464387cd34b7/62c2b6281fcf4198c38a3d57_Globe.svg',
  },
  {
    header: 'Formal Verification',
    article: 'Mathematical proofs of smart contract specifications, thesis and threats.',
    src: 'https://uploads-ssl.webflow.com/62b0519d9f36464387cd34b7/62c2b603270c79c0a686eeed_lightning-bolt.svg',
  },
  {
    header: 'Penetration Testing',
    article:
      'Penetration testing and attack simulation applied to the apps, websites and platforms that act as the front end of web3.',
    src: 'https://uploads-ssl.webflow.com/62b0519d9f36464387cd34b7/62c2b649abd781175a1c4328_switch.svg',
  },
  {
    header: 'Incident Response',
    article:
      'Ongoing support, monitoring and standby, ready to conduct immediate incident response actions to mitigate hacks and breaches.',
    src: 'https://uploads-ssl.webflow.com/62b0519d9f36464387cd34b7/62c2b6377f5d655f723a7604_padlock.svg',
  },
  {
    header: 'Testing Services',
    article:
      'Assisting project and development teams by writing and reviewing unit, integration, fuzz, and other test types to catch edge case vulnerabilities, increase test coverage and mitigate risk.',
    src: 'https://uploads-ssl.webflow.com/62b0519d9f36464387cd34b7/62c2b60db3c318c204c8d7e7_block.svg',
  },
  {
    header: 'On-Chain Monitoring',
    article:
      'Setting up, managing and analysing on-chain monitoring technology to help give confidence to stakeholders, increase transparency, and allow for faster incident response.',
    src: 'https://uploads-ssl.webflow.com/62b0519d9f36464387cd34b7/62c2b61b65c2ff82c66a5ab5_block-fi.svg',
  },
];

const ServicesPage = () => {
  return (
    <div className='services page'>
      <PageHeader
        header={
          <>
            Real Blockchain <span className='green-text'>Security</span>
          </>
        }
        paragraph={'Trusted by developers, end users, and corporations.'}
        sign={'services'}
      />
      <div className='services_wrapper'>
        <div className='services_wrapper__grid'>
          {CARDS.map((item, index) => (
            <ServicesCard key={index} card={item} />
          ))}
        </div>
      </div>
      <NextBanner />
    </div>
  );
};

export { ServicesPage };
