import './Timeline.css';
import { TimelineBlock } from './TimelineBlock/TimlineBlock';

const BLOCKS = [
  {
    text: 'Initial Consultation',
    main: 'Hashlock engages the client to learn about their specifc blockchain application and security needs.',
    img: {
      src: 'https://uploads-ssl.webflow.com/62b0519d9f36464387cd34b7/6454348b694e0ea98b2bc185_Consultation%205.svg',
      width: 400,
    },
  },
  {
    text: 'Project Scoping',
    main: 'Hashlock reviews the code and technology to scope the audit or security service to provide a quote and timeline.',
    img: {
      src: 'https://uploads-ssl.webflow.com/62b0519d9f36464387cd34b7/64542fa1cc5426eb54447a3f_Code%20Scoping.svg',
      width: 400,
    },
  },
  {
    text: 'Preliminary Report',
    main: 'Hashlock Privately Communicates our Findings in a Private Report.',
    second:
      'This closed source preliminary report contains all vulnerabilities found, and the recommended fixes to mitigate them.',
    img: {
      src: 'https://uploads-ssl.webflow.com/62b0519d9f36464387cd34b7/645481d8afdb2182f6a77b80_Findings%20Pie%20Chart%202.svg',
      width: 400,
    },
  },
  {
    text: 'Vulnerability Revisions',
    main: 'Revision Stage',
    second:
      'Hashlock re-audits the codebase to ensure all findings have been resolved or acknoweldged, and asssures no new vulnerabilities have been implemented.',
    img: {
      src: 'https://uploads-ssl.webflow.com/62b0519d9f36464387cd34b7/62d0c0e7fd079155591cd746_Hashlock-Secure-Graph.svg',
      width: 400,
    },
  },
  {
    text: 'Final Audit Report',
    main: `Hashlock's final report can be made public at the clients discretion. If all vulnerabilities are successfully mitigated, this will result in a secure rating.`,
    second: 'We assure all vulnerabilities are fixed so that the project can be secured.',
    img: {
      src: 'https://uploads-ssl.webflow.com/62b0519d9f36464387cd34b7/64e55556c3c8c335efbd32dc_Template%20-%20Audit%20cover-page.png',
      width: 400,
    },
  },
  {
    text: 'Promotion and Verification',
    main: 'Hashlock can promote the audit on various digital platforms to establish that the project is secure.',
    img: {
      src: 'https://uploads-ssl.webflow.com/62b0519d9f36464387cd34b7/65015e966e791eb112ada620_Audit%20Badge%20Rectangle%20V2%20for%20Website.png',
      width: 400,
    },
  },
  {
    text: 'Ongoing Support',
    main: 'Hashlock assists with active security services such as on-chain monitoring, bug bounty management, upgradeable security, and much more.',
    img: {
      src: 'https://uploads-ssl.webflow.com/62b0519d9f36464387cd34b7/645475f4dcabb600d8003fb9_Bot%204.5.svg',
      width: 250,
    },
  },
];

const Timeline = () => {
  return (
    <div className='timeline'>
      <div className='timeline_holder'>
        <div className='timeline_progress'></div>
        {BLOCKS.map((block, index) => (
          <TimelineBlock key={index} content={block} />
        ))}
      </div>
    </div>
  );
};

export { Timeline };
