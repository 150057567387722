import './TimelineBlock.css';

const TimelineBlock = ({ content }) => {
  return (
    <div className='timeline_block'>
      <div className='timeline_block__left'>
        <div className='timeline_block__text'>{content.text}</div>
      </div>
      <div className='timeline_block__center'>
        <div className='timeline_block__circle'></div>
      </div>
      <div className='timeline_block__right'>
        <div className='timeline_block__main'>{content.main}</div>
        <div className='timeline_block__second'>{content.second}</div>
        <img
          className='timeline_blcok__img'
          src={content.img.src}
          width={content.img.width}
          height='auto'
        />
      </div>
    </div>
  );
};

export { TimelineBlock };
