import './Header.css';
import { Logo } from '../Logo/Logo';
import { Button } from '../common/Button/Button';
import { Link, useNavigate } from 'react-router-dom';
import {
  ABOUT_ROUTE,
  BLOGS_ROUTE,
  CONTACT_ROUTE,
  HOME_ROUTE,
  REQUEST_AUDIT_PATH,
  SERVICES_ROUTE,
} from '../../constants/routes';
import { HeaderMotionLi } from '../animation/HeaderMotionLi/HeaderMotionLi';

const Header = () => {
  const navigate = useNavigate();
  return (
    <nav className='navbar'>
      <Logo />
      <ul className='navbar_list'>
        <Link to={HOME_ROUTE}>
          <HeaderMotionLi delay={0}>Home</HeaderMotionLi>
        </Link>
        <Link to={ABOUT_ROUTE}>
          <HeaderMotionLi delay={0.2}>About</HeaderMotionLi>
        </Link>
        <Link to={SERVICES_ROUTE}>
          <HeaderMotionLi delay={0.4}>Services</HeaderMotionLi>
        </Link>
        <Link to={BLOGS_ROUTE}>
          <HeaderMotionLi delay={0.6}>Blog</HeaderMotionLi>
        </Link>
        <Link to={REQUEST_AUDIT_PATH}>
          <HeaderMotionLi delay={0.8}>Request a Quote</HeaderMotionLi>
        </Link>
      </ul>
      <Button type='button' className='navbar_button' onClick={() => navigate(CONTACT_ROUTE)}>
        Contact us
      </Button>
    </nav>
  );
};

export { Header };
