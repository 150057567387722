import './Button.css';
import { motion } from 'framer-motion';

const Button = (props) => {
  const { className, children } = props;
  return (
    <motion.button
      {...props}
      className={`${className || ''} custom-button`}
      whileHover={{ scale: 0.9, boxShadow: 'inset -18px -1px 40px -23px rgba(255,255,255,0.76)' }}
      transition={{ type: 'spring', duration: 0.5 }}
    >
      {children}
    </motion.button>
  );
};

export { Button };
