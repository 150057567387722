import './Blog.css';
import { ServicesCard } from '../../components/ServicesCard/ServicesCard';
import { NextBanner } from '../../components/NextBanner/NextBanner';
import { PageHeader } from '../../components/PageHeader/PageHeader';

const CARDS = [
  {
    header: `Hashlock soon to launch 'trustedWeb3' initiative to promote blockchain security`,
    article: 'Hashlock soon to launch `trustedWeb3` initiative to promote blockchain security',
    src: 'https://uploads-ssl.webflow.com/62b0519d9f36464387cd34b7/62c2b6377f5d655f723a7604_padlock.svg',
  },
  {
    header: 'The Future of Blockchain and Smart Contracts with Generative AI: A Closer Look',
    article: 'The Future of Blockchain and Smart Contracts with Generative AI: A Closer Look',
    src: 'https://uploads-ssl.webflow.com/62b0519d9f36464387cd34b7/62c2b6377f5d655f723a7604_padlock.svg',
  },
];

const BlogPage = () => {
  return (
    <div className='blog page'>
      <PageHeader
        header={
          <>
            The Only Blockchain Cyber Security <span className='green-text'>Blog</span> You Need.
          </>
        }
        paragraph={'Trusted by developers, end users, and corporations.'}
        sign={'blog'}
      />
      <div className='blog_content'>
        {CARDS.map((item, key) => (
          <ServicesCard card={item} key={key} className='blog_card' />
        ))}
      </div>
      <NextBanner />
    </div>
  );
};

export { BlogPage };
