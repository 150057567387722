/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { Button } from '../../components/common/Button/Button';
import { Input } from '../../components/common/Input/Input';
import { Textarea } from '../../components/common/Textarea/Textarea';
import './RequestPage.css';

const RequestAnAuditPage = () => {
  const [fname, setFname] = useState('');
  const [sname, setSname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [projectName, setProjectName] = useState('');
  const [codeLanguage, setCodeLanguage] = useState('');
  const [linesNum, setLinesNum] = useState('');
  const [auditDate, setAuditDate] = useState('');
  const [country, setCountry] = useState('');
  const [moreInfo, setMoreInfo] = useState('');

  return (
    <div className='request page'>
      <PageHeader
        header={'Request an Audit'}
        paragraph={'Our team will send you an estimated quote within 24-48 hours!'}
        sign={'request'}
      />
      <form className='form'>
        <Input placeholder='First Name *' onChange={(e) => setFname(e.target.value)} required />
        <Input placeholder='Last Name' onChange={(e) => setSname(e.target.value)} />
        <Input placeholder='Email Address *' onChange={(e) => setEmail(e.target.value)} required />
        <Input placeholder='Phone Number *' onChange={(e) => setPhone(e.target.value)} required />
        <Input
          placeholder='Project Name *'
          onChange={(e) => setProjectName(e.target.value)}
          required
        />
        <Input placeholder='Code Language' onChange={(e) => setCodeLanguage(e.target.value)} />
        <Input
          placeholder='Estimated lines of code'
          onChange={(e) => setLinesNum(e.target.value)}
        />
        <Input
          placeholder='Expected audit start date *'
          onChange={(e) => setAuditDate(e.target.value)}
          required
        />
        <Input placeholder='Country or region' onChange={(e) => setCountry(e.target.value)} />
        <Textarea
          placeholder='Tell us more about your project and your audit requirements *'
          onChange={(e) => setMoreInfo(e.target.value)}
        />
        <Button type='submit' className='submit_button'>
          Submit
        </Button>
      </form>
    </div>
  );
};

export { RequestAnAuditPage };
