import './Ticker.css';
import { TickerItem } from './TickerItem/TickerItem';

const BANNER_TAGS = [
  'Web Design',
  'Web Development',
  'B2B ERP systems ',
  'eCommerce',
  'MedTech',
  'Artificial Inteligence',
  'Augmentet Reality',
  'Virtual Reality',
  ' Web3 Solutions',
  'Smart Contracts',
  'Tokenization',
  'Decentralized Finance',
  'NFT Integration',
];

const Ticker = () => {
  return (
    <div className='ticker-tape'>
      <div className='ticker'>
        {BANNER_TAGS.map((tag) => (
          <TickerItem key={tag}>{tag}</TickerItem>
        ))}
      </div>
    </div>
  );
};

export { Ticker };
