import './AboutPage.css';
import { NextBanner } from '../../components/NextBanner/NextBanner';
import { PageHeader } from '../../components/PageHeader/PageHeader';

const AboutPage = () => {
  return (
    <div className='about page'>
      <PageHeader
        header={
          <>
            <span className='green-text'>Trusted</span>, Fully
            <br /> Independent Smart <br /> Contract Auditing Firm
          </>
        }
        paragraph={
          <>
            We are on a mission to ensure that all stakeholders in blockchain
            <br /> innovation can utilise web3 <span className='green-text'>securely.</span>
          </>
        }
        sign={'about'}
      />
      <div className='about_content'>
        <div className='about_content__wrapper'>
          <div className='about_content__boxes'>
            <div className='about_content__box'>
              <img
                src='https://uploads-ssl.webflow.com/62b0519d9f36464387cd34b7/62c2b589c9f8dd907325bba8_flag.svg'
                className='inline'
                height='35rem'
              />
              <h4 className='inline'>Our Mission</h4>
              <p>
                To ensure that blockchain adoption and innovation can be widely used and trusted by
                the public, corporations and creators.
              </p>
            </div>
            <div className='about_content__box'>
              <img
                src='https://uploads-ssl.webflow.com/62b0519d9f36464387cd34b7/62c2b595f97098962dfeba96_star.svg'
                className='inline'
                height='35rem'
              />
              <h4 className='inline'>Our Story</h4>
              <p>
                Hashlock was born with the vision of conducting the most comprehensive blockchain
                security research possible and applying it with our clients to boost safe
                innovation. The founders and team quickly learned that this learning process will
                never end. Hashlock aims to influence blockchain innovation, regulation and usage to
                best reflect web3s original core values.
              </p>
            </div>
          </div>
          <div className='about_content__article'>
            <img
              src='https://uploads-ssl.webflow.com/62b0519d9f36464387cd34b7/63929f32629df30ddb50c401_Hashlock-sticker2-p-500.png'
              height='280rem'
            />
            <div className='about_content__text'>
              <h4>Why Choose Hashlock?</h4>
              <p>
                Our team is accessible, responsive, and highly skilled at both smart contract audits
                and other cyber security services that impact blockchain developments. Hashlock has
                two main KPI{`&rsquo;`}s we apply to every project.
              </p>
              <p>
                1. The amount of vulnerabilities we are able to find and correct. We source and
                recruit our team from bug-bounty competition-like settings to ensure we are able to
                find even the most obscure of logic errors in contracts and code.
              </p>
              <p>
                2. The education and awareness we are able to provide to the community and
                stakeholders. We believe our final reports are best used when they can educate users
                about a project and their investment into security.
              </p>
            </div>
          </div>
          <div className='about_content__article'>
            <div className='about_content__text'>
              <h4>A Community Focused, Collaborative Firm</h4>
              <p>
                Hashlock breaks the mould of stereotypical web3 auditing firms by having an active
                presence in the community, and by being highly accessible and collaborative in the
                way we work with our clients and partners.
              </p>
              <p>
                Catch us around at various functions or events in the industry, or ask our team to
                organise a meeting!
              </p>
            </div>
            <img
              src='https://uploads-ssl.webflow.com/62b0519d9f36464387cd34b7/63f7f330e69bb8081a10123f_Hashlock-dinner4-p-500.png'
              height='570rem'
            />
          </div>
          <div className='about_content__article3'>
            <h4>Core Leadership</h4>
            <p>
              The Hashlock team and mission had humble beginnings between two young professionals
              and academics. Hashlock was built upon its core activity of leading edge blockchain
              security research and by collaborating with great projects.
            </p>
            <p>
              As a result of early industry recognition and leading edge backing, Hashlock is a
              growing team of security geeks and innovation enthusiasts, and has grown into a team
              of academically focused security researchers.
            </p>
          </div>
          <div className='about_content__lead'>
            <div className='personal-card'>
              <img
                src='https://uploads-ssl.webflow.com/62b0519d9f36464387cd34b7/6501329090eb0f80ff8ceefd_Headshot_6_small.png'
                className='border-radius'
                height='280rem'
              />
              <h4>Jock Haslam</h4>
              <p>Co-founder and Director</p>
            </div>
            <div className='personal-card'>
              <img
                src='https://uploads-ssl.webflow.com/62b0519d9f36464387cd34b7/6501329990eb0f80ff8cf7a4_fletcher-hashlock_smaller.png'
                className='border-radius'
                height='280rem'
              />
              <h4>Fletcher Roberts</h4>
              <p>Co-founder and Director</p>
            </div>
            <div className='personal-card'>
              <img
                src='https://uploads-ssl.webflow.com/62b0519d9f36464387cd34b7/650133ae9e22f8b18ccddb86_Chris_Moore_-_Green.png'
                className='border-radius'
                height='280rem'
              />
              <h4>Chris Moore</h4>
              <p>Lead Security Researcher</p>
            </div>
          </div>
        </div>
      </div>
      <NextBanner />
    </div>
  );
};

export { AboutPage };
