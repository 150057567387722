import './Pageheader.css';
import { HeadSign } from '../common/HeadSign/HeadSign';

const PageHeader = ({ header, paragraph, sign }) => {
  return (
    <div className='request_header'>
      <HeadSign>{sign}</HeadSign>
      <h1>{header}</h1>
      <p className='request_header__under'>{paragraph}</p>
    </div>
  );
};

export { PageHeader };
