import { motion } from 'framer-motion';

const MotionLi = ({ children, className, animation, initial, transition }) => {
  return (
    <motion.li
      className={className || ''}
      whileHover={{ opacity: 1, transition: { duration: 0.3 } }}
      animate={{ ...animation }}
      initial={{ ...initial, transition: { duration: 0.3, delay: 0 } }}
      transition={{ ...transition }}
    >
      {children}
    </motion.li>
  );
};

export { MotionLi };
