import { useNavigate } from 'react-router';
import { NextBanner } from '../../components/NextBanner/NextBanner';
import { Ticker } from '../../components/Ticker/Ticker';
import { Timeline } from '../../components/Timeline/Timeline';
import { Button } from '../../components/common/Button/Button';
import './HomePage.css';
import { REQUEST_AUDIT_PATH } from '../../constants/routes';

const HomePage = () => {
  const navigate = useNavigate();
  return (
    <div className='home'>
      <div className='home_header'>
        <h1>
          Leading Edge <br /> <span>Web</span> and <span>Web3</span> & <br /> B2B Solutions
        </h1>
        <Button
          type='button'
          className='home_header__button'
          onClick={() => navigate(REQUEST_AUDIT_PATH)}
        >
          Request a Quote
        </Button>
      </div>
      <Ticker />
      <div className='home_timeline'>
        <div className='home_timeline__header'>
          <h4>
            Our Process to Secure Protocols and Business <br /> Applications.
          </h4>
        </div>
        <Timeline />
      </div>
      <NextBanner />
    </div>
  );
};

export { HomePage };
