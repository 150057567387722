import './ServicesCard.css';

const ServicesCard = ({ card, className }) => {
  console.log(card);

  return (
    <div className={`services_card ${className}`}>
      <img src={card.src} height={'50rem'} width='auto' />
      <h4>{card.header}</h4>
      <p>{card.article}</p>
    </div>
  );
};

export { ServicesCard };
