/* eslint-disable no-unused-vars */
import './ContactPage.css';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { Input } from '../../components/common/Input/Input';
import { Textarea } from '../../components/common/Textarea/Textarea';
import { Button } from '../../components/common/Button/Button';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import { REQUEST_AUDIT_PATH } from '../../constants/routes';

const ContactPage = () => {
  const navigate = useNavigate();
  const [fname, setFname] = useState('');
  const [sname, setSname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  return (
    <div className='contact page'>
      <PageHeader
        header={'Get In Touch'}
        paragraph={'Meet our team and get started!'}
        sign={'contact'}
      />
      <form className='form'>
        <Input placeholder='First Name' onChange={(e) => setFname(e.target.value)} />
        <Input placeholder='Last Name' onChange={(e) => setSname(e.target.value)} />
        <Input placeholder='Email Address' onChange={(e) => setEmail(e.target.value)} />
        <Input placeholder='Phone Number' onChange={(e) => setPhone(e.target.value)} />
        <Textarea placeholder='Your Message' onChange={(e) => setMessage(e.target.value)} />
        <Button type='submit' className='submit_button'>
          Submit
        </Button>
      </form>
      <div className='contact_redirect'>
        <h4>Or go to...</h4>
        <Button type='button' onClick={() => navigate(REQUEST_AUDIT_PATH)}>
          Request a Quote
        </Button>
      </div>
    </div>
  );
};

export { ContactPage };
