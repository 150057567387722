import './Footer.css';
import { Logo } from '../Logo/Logo';
import { Link } from 'react-router-dom';
import { ABOUT_ROUTE, REQUEST_AUDIT_PATH, SERVICES_ROUTE } from '../../constants/routes';
import { MotionLi } from '../animation/MotionLi/MotionLi';

const Footer = () => {
  return (
    <footer className='footer'>
      <ul className='footer_section'>
        <li className='footer_section__header'>
          <Logo />
        </li>
        <li>
          We collaborate with blockchain developers, project creators and corporations to ensure
          that web3 innovations are secure and successful.
        </li>
        <MotionLi>
          <u>some_email@gmail.com</u>
        </MotionLi>
        <li>
          <u>Tel: +380 05 123 4567</u>
        </li>
        <MotionLi>
          <a href='https://github.com/LonelyTrouvere'>GitHub</a>
        </MotionLi>
        <MotionLi>
          <a href='https://www.linkedin.com/in/stanislav-kobzar/'>LinkedIn</a>
        </MotionLi>
      </ul>
      <ul className='footer_section footer_smaller-section'>
        <li className='footer_section__header'>Quick Links</li>
        <MotionLi>
          <Link to={REQUEST_AUDIT_PATH}>Request a Quote</Link>
        </MotionLi>
        <MotionLi>
          <Link to={ABOUT_ROUTE}>About</Link>
        </MotionLi>
        <MotionLi>
          <Link to={SERVICES_ROUTE}>Services</Link>
        </MotionLi>
        <MotionLi>Contact</MotionLi>
        <li>What is a Smart Contract Security Audit?</li>
        <li>Careers</li>
        <li>Terms and Conditions</li>
        <li>Blockchain Cyber Insurance</li>
      </ul>
      <ul className='footer_section'>
        <li className='footer_section__header'>Sponsors</li>
      </ul>
    </footer>
  );
};

export { Footer };
