import { Routes, Route } from 'react-router';
import './App.css';
import { Header } from './components/Header/Header';
import { HomePage } from './pages/Home/HomePage';
import { ServicesPage } from './pages/Services/ServicesPage';
import { BlogPage } from './pages/Blog/BlogPage';
import { RequestAnAuditPage } from './pages/RequestAnAudit/RequestAnAuditPage';
import {
  ABOUT_ROUTE,
  BLOGS_ROUTE,
  CONTACT_ROUTE,
  HOME_ROUTE,
  REQUEST_AUDIT_PATH,
  SERVICES_ROUTE,
} from './constants/routes';
import { AboutPage } from './pages/About/AboutPage';
import { Footer } from './components/Footer/Footer';
import { motion } from 'framer-motion';
import { ContactPage } from './pages/ContactPage/ContactPage';

function App() {
  return (
    <div className='app'>
      <motion.div
        className='glow'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      ></motion.div>
      <Header />
      <Routes>
        <Route path={HOME_ROUTE} element={<HomePage />} />
        <Route path={ABOUT_ROUTE} element={<AboutPage />} />
        <Route path={SERVICES_ROUTE} element={<ServicesPage />} />
        <Route path={BLOGS_ROUTE} element={<BlogPage />} />
        <Route path={REQUEST_AUDIT_PATH} element={<RequestAnAuditPage />} />
        <Route path={CONTACT_ROUTE} element={<ContactPage />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
