import { MotionLi } from '../MotionLi/MotionLi';

const HeaderMotionLi = ({ children, delay }) => {
  const headerAnimation = {
    initial: {
      y: 35,
      opacity: 0,
    },
    animation: {
      y: 0,
      opacity: 0.5,
      transition: {
        duration: 1,
        delay: delay,
      },
    },
  };

  return (
    <MotionLi initial={headerAnimation.initial} animation={headerAnimation.animation}>
      {children}
    </MotionLi>
  );
};

export { HeaderMotionLi };
